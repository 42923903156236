import htmlEntities from './string'

const imageDataFromFile = file => {
  const fileId = file.meta.key.match(/^cache\/(.+)/)[1]

  return {
    url: file.preview,
    key: fileId,
    data: {
      position: 0,
      input: {
        id: fileId,
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.meta.name,
          mimeType: file.type,
          author: file.meta.author,
          alt: htmlEntities(file.meta.alt)
        }
      }
    }
  }
}

export default imageDataFromFile
