const ToggleAlert = () => {
  const elements = document.querySelectorAll('.js-close-alert')

  elements.forEach(element => {
    element.addEventListener('click', event => {
      const parent = element.closest('.alert__item')

      event.preventDefault()
      parent.classList.add('close')
    })
  })
}

export default ToggleAlert
