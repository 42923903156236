import flatpickr from "flatpickr"
import { Italian } from "flatpickr/dist/l10n/it.js"

const DateTimePicker = () => {
  const elements = document.querySelectorAll('.js-date-time-picker')

  elements.forEach(element => {
    element.flatpickr({
      locale: Italian,
      altInput: true,
      altFormat: "d/m/Y - H:i",
      defaultDate: element.value,
      enableTime: true
    })
  })
}

export default DateTimePicker
