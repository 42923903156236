import React from 'react'
import PropTypes from 'prop-types'
import ImageBox from '../ImageBox'

const Image = ({image, objectName, galleryName}) => {
  const baseName = `${objectName}[${galleryName}_attributes]`

  return (
    <ul className="grid">
      <li
        className="grid__item width-12-24 tab-8-24 desk-6-24 margin--top-2"
      >
        <ImageBox
          position={0}
          baseName={baseName}
          data={image.data}
          id={image.id}
          url={image.url}
        />
      </li>
    </ul>
  )
}

Image.propTypes = {
  objectName: PropTypes.string,
  galleryName: PropTypes.string,
  image: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
    data: PropTypes.shape({
      id: PropTypes.string,
      storage: PropTypes.string,
      metadata: PropTypes.shape({
        alt: PropTypes.string,
        size: PropTypes.number,
        filename: PropTypes.string,
        mime_type: PropTypes.string,
        mimeType: PropTypes.string
      })
    })
  })
}

Image.defaultProps = {
  objectName: null,
  galleryName: null,
  image: null
}

export default Image
