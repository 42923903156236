import flatpickr from "flatpickr"
import { Italian } from "flatpickr/dist/l10n/it.js"

const clearButton = (instance) => {
  const clearElement = document.createElement('div')
  clearElement.className = `
    flatpickr-clear
    button
    button--small
    margin--both-1
  `
  clearElement.innerHTML = "Clear"

  clearElement.addEventListener('click', () => {
    instance.clear()
    instance.close()
  })

  return clearElement
}

const DatePicker = () => {
  const elements = document.querySelectorAll('.js-date-picker')

  elements.forEach(element => {
    element.flatpickr({
      locale: Italian,
      altInput: true,
      altFormat: "d/m/Y",
      defaultDate: element.value,
      enableTime: false,
      onReady: function(dateObj, dateStr, instance) {
        const container = instance.calendarContainer
        const clearElement = clearButton(instance)

        container.append(clearElement)
      }
    })
  })
}

export default DatePicker
