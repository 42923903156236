import React, {useState} from 'react'
import PropTypes from 'prop-types'
import arrayMove from 'array-move'
import {sortableContainer} from 'react-sortable-hoc'
import AwsS3 from '@uppy/aws-s3'
import Uppy from '@uppy/core'
import {DashboardModal} from '@uppy/react'
import ImageList from './ImageList'
import imageDataFromFile from '../../utils/imageDataFromFile'

const SortableList = sortableContainer(({children}) => (
  <ul className="grid">{children}</ul>
))

const MultipleImagesUploader = ({images, objectName, galleryName}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [imagesData, setImagesData] = useState(images)

  const metaFields = [
    {
      id: 'name',
      name: 'Name',
      placeholder: 'File name'
    },
    {
      id: 'author',
      name: 'Author',
      placeholder: 'Author'
    },
    {
      id: 'alt',
      name: 'Alternate text',
      placeholder: 'Alternate text'
    }
  ]

  const handleModalClose = () => setModalOpen(false)

  const handleModalOpen = evt => {
    evt.preventDefault()

    setModalOpen(true)
  }

  const handleUploadSuccess = file => {
    const imageData = imageDataFromFile(file)

    setImagesData(previousImagesData => [...previousImagesData, imageData])
  }

  const onSortEnd = ({oldIndex, newIndex}) => {
    const newImagesData = arrayMove(imagesData, oldIndex, newIndex)

    setImagesData(newImagesData)
  }

  const uppy = Uppy()
    .use(AwsS3, {companionUrl: '/'})
    .on('upload-success', handleUploadSuccess)
    .on('complete', handleModalClose)

  return (
    <>
      <button
        type="button"
        onClick={handleModalOpen}
        className="button--primary"
      >
        Carica immagini
      </button>
      <DashboardModal
        uppy={uppy}
        closeModalOnClickOutside
        open={modalOpen}
        onRequestClose={handleModalClose}
        metaFields={metaFields}
      />

      <SortableList onSortEnd={onSortEnd} axis="xy">
        <ImageList
          images={imagesData}
          objectName={objectName}
          galleryName={galleryName}
        />
      </SortableList>
    </>
  )
}

MultipleImagesUploader.propTypes = {
  objectName: PropTypes.string.isRequired,
  galleryName: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape)
}

MultipleImagesUploader.defaultProps = {images: null}

export default MultipleImagesUploader
