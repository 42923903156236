import React from 'react'
import PropTypes from 'prop-types'
import {sortableElement} from 'react-sortable-hoc'
import ImageBox from '../ImageBox'

const SortableItem = sortableElement(({children}) => (
  <li
    className="grid__item width-12-24 tab-8-24 desk-6-24 margin--top-2"
  >
    {children}
  </li>
))

const ImageList = ({images, objectName, galleryName}) => {
  const baseName = `${objectName}[${galleryName}_attributes]`

  return (
    <>
      {
        images.map((image, index) => (
          <SortableItem key={image.id || image.key} index={index}>
            <ImageBox
              position={index}
              baseName={`${baseName}[${index}]`}
              data={image.data}
              id={image.id}
              url={image.url}
            />
          </SortableItem>
        ))
      }
    </>
  )
}

ImageList.propTypes = {
  objectName: PropTypes.string.isRequired,
  galleryName: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape)
}

ImageList.defaultProps = {images: null}

export default ImageList
