import React from 'react'
import ReactDOM from 'react-dom'
import MultipleImagesUploader from '../components/MultipleImagesUploader'

const GalleryUploader = () => {
  const elements = document.querySelectorAll('.js-gallery-uploader')

  elements.forEach(element => {
    const objectName = element.getAttribute('data-object-name')
    const galleryName = element.getAttribute('data-gallery-name')
    const images = element.getAttribute('data-images')

    ReactDOM.render(
      <MultipleImagesUploader
        objectName={objectName}
        galleryName={galleryName}
        images={JSON.parse(images)}
      />,
      element
    )
  })
}

export default GalleryUploader
