import Choices from 'choices.js'

const Select = () => {
  const elements = document.querySelectorAll('.js-select')

  elements.forEach(element => {
    const options = {
      removeItems: true,
      removeItemButton: true,
      searchEnabled: true
    }
    const choice = new Choices(element, options)

    return choice
  })
}

export default Select
