import Rails from '@rails/ujs'
import Select from '../behaviours/Select'
import ToggleAlert from '../behaviours/ToggleAlert'
import ToggleCanvasMenu from '../behaviours/ToggleCanvasMenu'
import ToggleDropDownMenu from '../behaviours/ToggleDropDownMenu'
import GalleryUploader from '../behaviours/GalleryUploader'
import SingleImageUploader from '../behaviours/SingleImageUploader'
import ToggleTabs from '../behaviours/ToggleTabs'
import MapBox from '../behaviours/MapBox'
import DateTimePicker from '../behaviours/DateTimePicker'
import DatePicker from '../behaviours/DatePicker'

Rails.start()
ToggleAlert()
ToggleCanvasMenu()
ToggleDropDownMenu()
Select()
GalleryUploader()
SingleImageUploader()
ToggleTabs()
MapBox()
DateTimePicker()
DatePicker()

import "../stylesheets/admin.scss"