import React, {useState} from 'react'
import PropTypes from 'prop-types'
import HiddenInputFor from './Inputs/HiddenInputFor'

const ImageBox = ({id, url, baseName, position, data}) => {
  const [toDestroy, setToDestroy] = useState(false)
  const inputId = `checkbox-${position}`

  const {author, alt} = data && (data.metadata || data.input.metadata)

  return (
    <article className="card draggable">
      <img title={author} alt={alt} src={url} className="img--cover--fixed" />
      <div className="card__body">
        <HiddenInputFor
          name="position"
          value={position}
          baseName={baseName}
        />
        <HiddenInputFor
          name="_destroy"
          value={toDestroy ? 1 : 0}
          baseName={baseName}
        />

        {id && <HiddenInputFor baseName={baseName} name="id" value={id} />}

        {data && data.input
          && (
            <HiddenInputFor
              name="attachment"
              baseName={baseName}
              value={JSON.stringify(data.input)}
            />
          )}

        <div className="boolean">
          <input
            id={inputId}
            className="form__input boolean"
            type="checkbox"
            checked={toDestroy}
            onChange={() => setToDestroy(!toDestroy)}
          />
          <label className="form__label boolean" htmlFor={inputId}>
            Elimina immagine
          </label>
        </div>
      </div>
    </article>
  )
}

ImageBox.propTypes = {
  id: PropTypes.number,
  url: PropTypes.string.isRequired,
  baseName: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  data: PropTypes.shape().isRequired
}

ImageBox.defaultProps = {id: null}

export default ImageBox
