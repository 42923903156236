import React from 'react'
import ReactDOM from 'react-dom'
import ImageUploader from '../components/ImageUploader'

const SingleImageUploader = () => {
  const elements = document.querySelectorAll('.js-image-uploader')

  elements.forEach(element => {
    const objectName = element.getAttribute('data-object-name')
    const galleryName = element.getAttribute('data-image-name')
    const image = element.getAttribute('data-image')

    ReactDOM.render(
      <ImageUploader
        objectName={objectName}
        galleryName={galleryName}
        image={JSON.parse(image)}
      />,
      element
    )
  })
}

export default SingleImageUploader
