const ToggleCanvasMenu = () => {
  const elements = document.querySelectorAll('.js-toggle-canvas-menu')

  elements.forEach(element => {
    element.addEventListener('click', event => {
      event.preventDefault()

      const canvas = document.querySelector('.canvas__aside')
      canvas.classList.toggle('is-shifted')
    })
  })
}

export default ToggleCanvasMenu
