import React from 'react'
import PropTypes from 'prop-types'

const HiddenInputFor = ({baseName, name, value}) => (
  <input
    type="hidden"
    name={`${baseName}[${name}]`}
    value={value}
  />
)

HiddenInputFor.propTypes = {
  baseName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
}

export default HiddenInputFor
