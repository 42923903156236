import React, {useState} from 'react'
import PropTypes from 'prop-types'
import AwsS3 from '@uppy/aws-s3'
import Uppy from '@uppy/core'
import {DashboardModal} from '@uppy/react'
import Image from './Image'
import imageDataFromFile from '../../utils/imageDataFromFile'

const ImageUploader = ({image, objectName, galleryName}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [imageData, setImageData] = useState(image.data && image)

  const metaFields = [
    {
      id: 'name',
      name: 'Name',
      placeholder: 'File name'
    },
    {
      id: 'author',
      name: 'Author',
      placeholder: 'Author'
    },
    {
      id: 'alt',
      name: 'Alternate text',
      placeholder: 'Alternate text'
    }
  ]

  const handleModalClose = () => setModalOpen(false)

  const handleModalOpen = evt => {
    evt.preventDefault()

    setModalOpen(true)
  }

  const handleUploadSuccess = file => {
    setImageData(imageDataFromFile(file))
  }

  const uppy = Uppy()
    .use(AwsS3, {companionUrl: '/'})
    .on('upload-success', handleUploadSuccess)
    .on('complete', handleModalClose)

  uppy.setOptions({restrictions: {maxNumberOfFiles: 1}})

  return (
    <>
      <button
        type="button"
        onClick={handleModalOpen}
        className="button--primary"
      >
        Carica immagine
      </button>

      <DashboardModal
        uppy={uppy}
        closeModalOnClickOutside
        open={modalOpen}
        onRequestClose={handleModalClose}
        metaFields={metaFields}
      />

      { imageData && (
        <Image
          image={imageData}
          objectName={objectName}
          galleryName={galleryName}
        />
      )}
    </>
  )
}

ImageUploader.propTypes = {
  objectName: PropTypes.string,
  galleryName: PropTypes.string,
  image: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
    data: PropTypes.shape({
      id: PropTypes.string,
      storage: PropTypes.string,
      metadata: PropTypes.shape({
        alt: PropTypes.string,
        size: PropTypes.number,
        filename: PropTypes.string,
        mime_type: PropTypes.string,
        mimeType: PropTypes.string
      })
    })
  })
}

ImageUploader.defaultProps = {
  objectName: null,
  galleryName: null,
  image: null
}

export default ImageUploader
