import mapboxgl from 'mapbox-gl'

mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN

const addMarker = (map, parsedMarker) => {
  const {name, lonlat} = parsedMarker
  const popup = new mapboxgl.Popup().setText(name)

  const marker = new mapboxgl
    .Marker()
    .setLngLat(lonlat)
    .setPopup(popup)
    .addTo(map)

  return marker
}

const parsedMarkers = rawMarkers => {
  let markers = null

  try {
    markers = JSON.parse(rawMarkers)
  } catch (e) {
    markers = []
  }

  return markers
}

const firstMarkers = markers => {
  if (Array.isArray(markers) && markers.length !== 0) {
    return markers[0].lonlat
  }

  return [0, 0]
}

const MapBox = () => {
  const elements = document.querySelectorAll('.js-map-box')

  elements.forEach(element => {
    const target = document.querySelector(element.dataset.target)
    const stayMarkers = parsedMarkers(element.dataset.stayMarkers)
    const poiMarkers = parsedMarkers(element.dataset.poiMarkers)
    const markers = stayMarkers.concat(poiMarkers)

    const map = new mapboxgl.Map({
      container: target,
      style: 'mapbox://styles/mapbox/outdoors-v11',
      center: firstMarkers(markers),
      zoom: 3
    })

    map.addControl(new mapboxgl.NavigationControl())

    stayMarkers.map(marker => (addMarker(map, marker)))
    poiMarkers.map(coordinate => (addMarker(map, coordinate)))

    return map
  })
}

export default MapBox
