const initContents = contents => {
  contents.forEach(content => {
    if (!content.classList.contains('is-active')) {
      content.setAttribute('style', 'height: 0px')
    } else {
      const height = `${content.clientHeight}px`

      content.setAttribute('style', `height: ${height}`)
    }
  })
}

const initLinks = links => {
  links.forEach(link => {
    link.addEventListener('click', event => {
      event.preventDefault()

      const target = link.getAttribute('data-target')
      const content = document.querySelector(target)
      const arrow = link.querySelector('.js-dropdown-arrow')

      if (content.classList.contains('is-active')) {
        if (arrow) {
          arrow.classList.remove('is-open')
        }
        link.classList.remove('is-open')
        content.style.height = '0'
        content.addEventListener('transitionend', () => {
          content.classList.remove('is-active')
        }, {once: true})
      } else {
        if (arrow) {
          arrow.classList.add('is-open')
        }
        content.setAttribute('style', 'height: auto')
        content.classList.add('is-active')
        link.classList.remove('is-open')

        const height = `${content.clientHeight}px`

        content.style.height = '0px'

        setTimeout(() => {
          content.setAttribute('style', `height: ${height}`)
        }, 0)
      }
    })
  })
}

const ToggleDropDownMenu = () => {
  const links = document.querySelectorAll('.js-dropdown-link')
  const contents = document.querySelectorAll('.js-dropdown-content')

  initContents(contents)
  initLinks(links)
}

export default ToggleDropDownMenu
